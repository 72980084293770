import { useMemo } from 'react'

import clsx from 'clsx'
import { Button } from 'components/common'
import AvatarGroup from 'components/common/AvatarGroup/AvatarGroup'
import { UserPlus } from 'react-feather'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { User } from 'types'

import styles from './CollaboratorsButton.module.scss'
import { practicesApi } from '../../api'

const createAvatarGroupItems = (users: User[]) => {
  return users.map((user) => ({
    content: user.initials,
    src: user.avatar_public_path,
    id: user.id,
  }))
}

const CollaboratorsButton = (): JSX.Element | null => {
  const classes = clsx(styles.root)

  const { id: practiceId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const collaboratorsApi = practicesApi.useGetCollaboratorsByPracticeIdQuery(
    practiceId ?? '',
    {
      skip: !practiceId,
    }
  )

  const handleAddClick = () => {
    if (practiceId) {
      navigate({
        pathname: 'collaborate',
        hash: location.hash,
        search: location.search,
      })
    } else {
      // show toast
    }
  }

  const collaboratorsList = useMemo(() => {
    if (
      !collaboratorsApi.isSuccess ||
      !collaboratorsApi.data ||
      !collaboratorsApi.data.data
    ) {
      return []
    }

    return createAvatarGroupItems(collaboratorsApi.data.data)
  }, [collaboratorsApi.data, collaboratorsApi.isSuccess])

  if (!collaboratorsApi.isSuccess) {
    return null
  }

  return (
    <div className={classes}>
      {collaboratorsList.length !== 0 && (
        <AvatarGroup
          items={collaboratorsList}
          onClick={handleAddClick}
          maxVisibleItems={4}
        />
      )}
      <Button
        variant="subtle"
        icon={<UserPlus />}
        iconLabel="add collaborators"
        size="small"
        onClick={handleAddClick}
      />
    </div>
  )
}

export default CollaboratorsButton
